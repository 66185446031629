import styled from "styled-components"
import Container from "@material-ui/core/Container"

export const Wrapper = styled(Container)`
  background-color: #040E1C;
  margin-bottom: 24px;
`

export const SectionTitle = styled.h2`
    color: #fff;
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: left;
    text-transform: uppercase;

    &::after {
        
    }
`

export const ServiceItem = styled.div`
    background-size: cover;
    background-position: 50% 50%;
    background-color: #233E67;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    cursor: pointer;

    height: 200px;
    padding: 2em;

    &:hover {
        &::before {
            background-color: rgba(0, 0, 0, .75);
        }
        .description {
            opacity: 1;
        }
    }

    &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        content: "";
        transition: .3s all ease-in;
    }

  .title, .description {
      position: absolute;
      z-index: 10;
      text-shadow: 1px 2px 1px rgba(0, 0, 0, .4);
  }

  .title {
      top: 20px;
      left: 20px;
      font-size: 22px;
      font-weight: 500;
      margin: 0;
      width: calc(100% - 40px);
  }

  .description {
      bottom: 20px;
      right: 20px;
      text-align: right;
      max-width: 80%;
      opacity: 0;
      transition: .3s all ease-in;

      ${({ theme }) => theme.breakpoints.down("xs")} {
          opacity: 1;
      }
  }
`