import styled from "styled-components"
import { Grid } from "@material-ui/core"
import Container from "@material-ui/core/Container"

export const Wrapper = styled.div`
    // padding-top: 2em;
    // padding-bottom: 2em;
    margin-bottom: 24px;
    
    & > div {
        background-color: ${props => props.lightContentArea ? "#F4F8FE" : "#050A17" };
    }

    .tiles > .tile:nth-child(odd) {
        & > .image { order: 2; }
        & > .content { order: 1; text-align: right; }

        ${({ theme }) => theme.breakpoints.down("sm")} {
            & > .image, & > .content { order: unset; text-align: left; }
        }
  }
`

export const HighlightContainer = styled(Container)`
  padding: 0;
  max-width: 1232px;
`

export const ImageHighlight = styled(Grid)`
  padding: 0;
`

export const Image = styled(Grid)`
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
        order: -1;
    }
`

export const Content = styled(Grid)`
    padding: 3rem;
`

export const Title = styled.h2`
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 2.5em;
    margin-bottom: 20px;
    line-height: 1.1em;
`