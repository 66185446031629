import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled(Container)`
  margin-bottom: 24px;
`
export const ImageContainer = styled(GatsbyImage)`
  aspect-ratio: 16/9;
`
