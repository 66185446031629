import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { Grid } from "@material-ui/core"

export const Wrapper = styled(Container)`
    padding: 0 24px 0;
    margin-bottom: 24px;
    background-color: ${props => props.lightContentArea ? "#FFF" : "transparent" };

    ${({ theme }) => theme.breakpoints.down("sm")} {
        .tiles > .tile {
            & > .image, & > .content { order: unset !important; text-align: left !important; }
        }
    }

    .tiles:not(.flip) > .tile:nth-child(odd) {
        & > .image { order: 2; }
        & > .content { order: 1; text-align: right; }
    }
    .tiles.flip > .tile:nth-child(even) {
        & > .image { order: 2; }
        & > .content { order: 1; text-align: right; }
    }
  }
`

export const Tile = styled(Grid)`
    align-items: center;
    overflow: hidden;
    background-color: ${props => props.lightContentArea ? "#F4F8FE" : "#000" };
    line-height: 1;
    min-height: 400px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        height: auto;
    }

    & > .image {
        .gatsby-image-wrapper {
            width: 100%;
            height: 400px;
        }
        // img {
        //     width: 100%;
        //     height: 400px;
        //     object-fit: cover;
        //     object-position: 50% 50%;
        // }
    }

    & > .content {
        padding: 3em 3em;
        color: ${props => props.lightContentArea ? "#000" : "#fff" };

        ul {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: .5rem;
            }
        }
    }
`

export const SectionTitle = styled.h2`
    color: ${props => props.lightContentArea ? "#0096c6" : "#00b3f3" };
    font-size: 2.5em;
    margin-bottom: 20px;
`

export const ServiceTitle = styled.h4`
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 2.5em;
    line-height: 1em;

    a { color: inherit; text-decoration: none; }

    ${({ theme }) => theme.breakpoints.down("xs")} {
        font-size: 2em;
    }
`

export const ServiceDescription = styled.p`
    //color: #fff;
    font-weight: 300;
    line-height: 1.4em;
`