import React from "react"
import * as S from "./image-grid.styles.jsx"
import { Grid } from "@material-ui/core"

const ImageGrid = ({ imageRow }) => {
  if (!imageRow) return null
  return (
    <S.Wrapper>
      {imageRow.map((row, rowIndex) => (
        <Grid container direction="row" key={`row-${rowIndex}`}>
          <Grid className="image" item md={6} xs={12}>
            <S.ImageContainer
              image={row.image1.localFile.childImageSharp.gatsbyImageData}
              alt={row.image1.altText}
            />
          </Grid>
          <Grid className="image" item md={6} xs={12}>
            <S.ImageContainer
              image={row.image2.localFile.childImageSharp.gatsbyImageData}
              alt={row.image2.altText}
            />
          </Grid>
        </Grid>
      ))}
    </S.Wrapper>
  )
}
export default ImageGrid
