import styled from "styled-components"
import Container from "@material-ui/core/Container"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const Wrapper = styled(Container)`
    margin-bottom: 24px;
`
export const Carousel = styled(Slider)`
    .slick-prev, .slick-next {
        width: 50px;
        height: 50px;
        z-index: 10;

        &:before {
            font-size: 80px;
            font-family: unset;
        }
    }

    .slick-prev { left: 25px; &:before { content: "\\2039" } }
    .slick-next { right: 25px; &:before { content: "\\203A" } }

    ${({ theme }) => theme.breakpoints.down("xs")} {
        .slick-prev, .slick-next {
            width: 25px;
            height: 25px;

            &:before {
                font-size: 25px;
            }
        }

        .slick-prev { left: 10px; }
        .slick-next { right: 10px; }
    }
`

export const CarouselItem = styled.div`
    height: 600px;
    background-size: cover;
    background-position: 50% 50%;
    color: #fff;
    position: relative;
    padding: 0 2rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, .5);

    .title {
        text-align: left;
        font-size: 3em;
        flex-basis: 100%;
        padding: 0 15px;
        max-width: calc(100% - 170px);
        margin: 0 0 200px;
        padding: 0;

        ${({ theme }) => theme.breakpoints.down("sm")} {
            font-size: 2em;
        }

        ${({ theme }) => theme.breakpoints.down("xs")} {
            max-width: calc(100% - 75px);
            margin-bottom: 0;
            text-align: center;
        }
    }

    .description {
        position: absolute;
        width: 50%;
        right: 2em;
        bottom: 2em;
        text-align: right;
        font-size: 1.5em;

        ${({ theme }) => theme.breakpoints.down("xs")} {
            position: static;
            flex-basis: 100%;
            text-align: center;
            width: calc(100% - 75px);
        }
    }
`